// const endpoint = "http://localhost:3161";
const endpoint = "https://earniapi.com";
const amazonEndpoint =
  "https://7mobilestaticimages.s3.eu-central-1.amazonaws.com/videocallpro";

const hostname = window.location.hostname;
const webEndpoint =
  hostname === "localhost" ? `http://${hostname}:3000` : `https://${hostname}`;

export { endpoint, amazonEndpoint, webEndpoint };
