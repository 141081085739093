import React from "react";
import ReactDOM from "react-dom";

import { CssBaseline } from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core/styles";

import App from "./App";
import AppStateProvider, { useAppState } from "./state";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from "react-router-dom";
import { ConnectOptions } from "twilio-video";
import ErrorDialog from "./components/ErrorDialog/ErrorDialog";
import LoginPage from "./components/LoginPage/LoginPage";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import theme from "./theme";
import "./types";
import { VideoProvider } from "./components/VideoProvider";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import EnterRoom from "./components/ComponentsFE/EnterRoom";
import Session from "./components/ComponentsFE/Session";
import SessionSuccess from "./components/ComponentsFE/SessionSuccess";
import ConnectAccount from "./components/ComponentsFE/ConnectAccount";
import ConnectAccountSuccess from "./components/ComponentsFE/ConnectAccountSuccess";
import SetNickname from "./components/ComponentsFE/SetNickname";
import PaymentWaiting from "./components/ComponentsFE/PaymentWaiting";

// See: https://media.twiliocdn.com/sdk/js/video/releases/2.0.0/docs/global.html#ConnectOptions
// for available connection options.
const connectionOptions: ConnectOptions = {
  bandwidthProfile: {
    video: {
      mode: "collaboration",
      dominantSpeakerPriority: "standard",
      renderDimensions: {
        high: { height: 1080, width: 1920 },
        standard: { height: 720, width: 1280 },
        low: { height: 90, width: 160 }
      }
    }
  },
  dominantSpeaker: true,
  maxAudioBitrate: 12000,
  networkQuality: { local: 1, remote: 1 },
  preferredVideoCodecs: [{ codec: "VP8", simulcast: true }]
};

const stripePromise = loadStripe(
  "pk_test_51HMtpdDdZrGlpS4ugpoVkIsaVczz7xP9SNhZ6R1Niv20Hvyge9ZO3JBT3uyn8MDVmjzlw08OwtPj2lRgdFubKOSF00UZwOhbBy"
);

const VideoApp = () => {
  const { error, setError } = useAppState();

  return (
    <VideoProvider options={connectionOptions} onError={setError}>
      <ErrorDialog dismissError={() => setError(null)} error={error} />
      <App />
    </VideoProvider>
  );
};

ReactDOM.render(
  <Elements stripe={stripePromise}>
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <AppStateProvider>
          <Switch>
            <PrivateRoute exact path="/finished-binding">
              <div />
            </PrivateRoute>
            <PrivateRoute exact path="/connect-account-success">
              <ConnectAccountSuccess />
            </PrivateRoute>

            <PrivateRoute exact path="/connect-account">
              <ConnectAccount />
            </PrivateRoute>

            <PrivateRoute exact path="/">
              <div></div>
            </PrivateRoute>
            <PrivateRoute path="/room/:attendeeId">
              <VideoApp />
            </PrivateRoute>
            <Route exact path="/session-success/:attendeeId">
              <SessionSuccess />
            </Route>
            <Route exact path="/session/:sessionId">
              <Session />
            </Route>
            <Route exact path="/nickname/:attendeeId">
              <SetNickname />
            </Route>
            <Route exact path="/session-payment-waiting/:sessionId/:attendeeId">
              <PaymentWaiting />
            </Route>
            <Route exact path="/enterroom">
              <EnterRoom />
            </Route>
            <Route path="/login">
              <LoginPage />
            </Route>
            <Redirect to="/" />
          </Switch>
        </AppStateProvider>
      </Router>
    </MuiThemeProvider>
  </Elements>,
  document.getElementById("root")
);
