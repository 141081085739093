import React, { useState, useEffect } from "react";
import { CircularProgress } from "@material-ui/core";
import {
  makeStyles,
  Theme,
  styled,
  createStyles
} from "@material-ui/core/styles";
import { useLocation, useHistory, useParams } from "react-router-dom";
import notchImage from "../../images/whiteContainerPathX.png";
import moment from "moment";

import { SessionInformation } from "./model";
import { endpoint, amazonEndpoint } from "../../network/constants";
import dateIcon from "../../images/dateIcon.png";
import timeIcon from "../../images/timeIcon.png";
import emailIcon from "../../images/emailIcon.png";
import worldIcon from "../../images/worldIcon.png";

import { fetchSession } from "../../network";
import axios from "axios";

import "../../App.css";

import theme from "../../theme";

const getFunction = () => {
  return "p";
};

interface RouteParams {
  attendeeId: string;
}

const SessionSuccess = (props: any) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      container: {
        backgroundColor: theme.palette.background.default
      },
      subContainer: {
        display: "flex",
        flex: 1,
        backgroundColor: "rgba(243,242,255,1)",
        justifyContent: "center",
        alignItems: "flex-start",
        flexDirection: "row",
        marginTop: "30px",
        [theme.breakpoints.down("sm")]: {
          flexDirection: "column",
          alignItems: "center"
        }
      },
      imageContainer: {
        height: "100%",
        width: 340,
        marginRight: 20,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        borderRadius: 55,
        [theme.breakpoints.down("sm")]: {
          display: "none"
        }
      },
      imageContent: {
        width: 340
      },
      contentContainer: {
        display: "flex",
        backgroundColor: "clear",
        width: "100%",
        maxWidth: 500,

        minHeight: 1000,
        height: "100%",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "column",
        [theme.breakpoints.up("sm")]: {
          minWidth: 500
        },
        [theme.breakpoints.down("sm")]: {
          width: "100%",
          justifyContent: "flex-start",
          alignItems: "center"
        }
      },
      contentDetailContainer: {
        display: "flex",
        backgroundColor: "white",
        borderRadius: 55,
        width: "100%",
        flexDirection: "column",
        overflow: "hidden",
        [theme.breakpoints.down("xs")]: {
          width: "90%"
        }
      },
      contentTitle: {
        marginLeft: 40,
        fontSize: 26,
        marginTop: 32,
        marginRight: 40,
        display: "block",
        color: "rgba(17,16,32,0.8)",
        fontFamily: "IBMPlexSans-Medium",
        [theme.breakpoints.down("xs")]: {
          marginLeft: 15
          // fontSize: 22
        }
      },
      contentSubtitle: {
        display: "flex",
        marginLeft: 40,
        marginRight: 40,
        marginBottom: 80,
        flexDirection: "column",
        textAlign: "justify",
        color: "rgba(17,16,32,0.8)",
        fontSize: 17,
        fontFamily: "IBMPlexSans",
        [theme.breakpoints.down("xs")]: {
          marginLeft: 15,
          marginRight: 15,
          marginBottom: 30
        }
      },
      nameTitle: {
        margin: 0,
        padding: 0,
        marginTop: 230,
        marginLeft: 40,
        color: "white",
        display: "block",
        fontSize: 20,
        fontFamily: "IBMPlexSans-Medium",
        [theme.breakpoints.down("xs")]: {
          marginLeft: 15,
          marginRight: 15,
          marginBottom: 15,
          marginTop: 170
        }
      },
      nameDescription: {
        margin: 0,
        padding: 0,
        marginTop: 1,
        marginLeft: 40,
        marginRight: 40,
        color: "rgba(210,208,225,1)",
        fontSize: 17,
        letterSpacing: 0.4,
        fontFamily: "IBMPlexSans",
        textAlign: "justify",
        [theme.breakpoints.down("xs")]: {
          marginLeft: 15,
          marginRight: 15,
          marginBottom: 15
        }
      },
      dateDetailContainer: {
        display: "flex",
        height: 22,
        marginTop: 17,
        alignItems: "center"
      },
      dateIcon: {
        width: 17,
        height: 19,
        marginLeft: 40,
        [theme.breakpoints.down("xs")]: {
          marginLeft: 15
        }
      },
      dateTitle: {
        padding: 0,
        margin: 0,
        color: "white",
        marginLeft: 20,
        fontFamily: "IBMPlexSans-Medium",
        fontSize: 17
      },
      timeDetailContainer: {
        display: "flex",
        height: 22,
        marginTop: 18,
        alignItems: "center"
      },
      timeIcon: {
        width: 12,
        height: 18,
        marginLeft: 43,
        [theme.breakpoints.down("xs")]: {
          marginLeft: 16
        }
      },
      timeTitle: {
        padding: 0,
        margin: 0,
        color: "white",
        marginLeft: 21,
        fontFamily: "IBMPlexSans-Medium",
        fontSize: 17
      },
      domainDetailContainer: {
        display: "flex",
        height: 22,
        marginTop: 18,
        alignItems: "center"
      },
      domainIcon: {
        width: 18,
        height: 18,
        marginLeft: 40,
        [theme.breakpoints.down("xs")]: {
          marginLeft: 15
        }
      },
      domainTitle: {
        padding: 0,
        margin: 0,
        color: "white",
        marginLeft: 18,
        fontFamily: "IBMPlexSans-Medium",
        fontSize: 17
      },
      contactDetailContainer: {
        display: "flex",
        height: 22,
        marginTop: 19,
        alignItems: "center",
        marginBottom: 50,
        [theme.breakpoints.down("xs")]: {
          marginBottom: 30
        }
      },
      contactImage: {
        width: 18,
        height: 14,
        marginLeft: 40,
        [theme.breakpoints.down("xs")]: {
          marginLeft: 15
        }
      },
      contactTitle: {
        padding: 0,
        margin: 0,
        color: "white",
        marginLeft: 18,
        fontFamily: "IBMPlexSans-Medium",
        fontSize: 17
      },
      joinSessionContainer: {
        display: "flex",
        height: 55,
        width: "80%",
        backgroundColor: "rgba(255,170,119,1)",
        alignSelf: "center",
        marginTop: 20,
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 27,
        [theme.breakpoints.down("xs")]: {
          width: "90%",
          alignItems: "center",
          alignSelf: "auto"
        }
      },
      joinSessionTitle: {
        fontFamily: "IBMPlexSans-SemiBold",
        fontSize: 20,
        color: "black"
      },
      sessionDetailContainer: {
        alignSelf: "center",
        marginTop: 15
      },
      sessionDetailTitle: {
        margin: 0,
        padding: 0,
        color: "rgba(17,16,32,1)",
        fontFamily: "IBMPlexSans-Italic",
        fontSize: 17
      },
      contentTitleSubtitleContainer: {
        height: "100%",
        width: "100%",
        marginBottom: -200,
        zIndex: 2,
        minHeight: 250,
        [theme.breakpoints.down("xs")]: {
          marginBottom: -100
        }
      },
      subtitleContainerBackground: {
        backgroundImage: `url(${notchImage})`,
        height: "100%",
        width: "100%",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "left",
        backgroundPositionY: "-50px",
        backgroundSize: "contain",
        zIndex: 1
      },
      readMoreTitle: {
        fontFamily: "IBMPlexSans",
        fontWeight: "bold",
        fontSize: 15,
        background: "transparent",
        alignSelf: "flex-start",
        color: "rgb(17, 16, 32)"
      },
      readMoreBio: {
        fontFamily: "IBMPlexSans",
        fontWeight: "bold",
        fontSize: 15,
        background: "transparent",
        alignSelf: "flex-start",
        color: "rgb(210, 208, 225)"
      }
    })
  );

  const readMoreLength = 200;
  const [isLoading, setLoading] = useState(true);
  const history = useHistory();
  const location = useLocation();
  const [readMoreContentDetail, setReadMoreContentDetail] = useState("IDLE");
  const [readMoreUserDetail, setReadMoreUserDetail] = useState("IDLE");

  const [sessionInformation, setSessionInformation] = useState<
    SessionInformation
  >({
    session_id: null,
    user_id: null,
    start_date: null,
    start_time: null,
    end_time: null,
    img_url: null,
    name: null,
    detail: null,
    price: null,
    inf_name: null,
    inf_url: null,
    inf_bio: null
  });
  const [roomImage, setRoomImage] = useState("");

  const params = useParams<RouteParams>();
  console.log("** > ", params.attendeeId);

  useEffect(() => {
    const pathSplit = location.pathname.split("/");

    console.log("location > ", location);

    async function fetchSessionByAttendeeFunction(attendeeId: string) {
      const simplend = `${endpoint}/session/detail-by-attendee-id/${attendeeId}`;

      axios
        .get(simplend)
        .then(rs => {
          const { data } = rs;
          console.log("dat > ", data);
          const { session } = data.result;
          setSessionInformation(session);
        })
        .catch(err => {
          console.log("err > ", err);
        });
    }

    console.log("session information");

    setLoading(false);
    fetchSessionByAttendeeFunction(params.attendeeId);
  }, []);

  useEffect(() => {}, []);

  const isImageStatic = (imageUrl: String) => {
    switch (imageUrl) {
      case "doctors_potrait":
      case "fitness_exercise":
      case "influencer_camera":
      case "investment_plan":
      case "lawyer_in_office":
      case "nutritionist_coach":
      case "psychic_surgery":
      case "psychological_notes":
      case "yoga_exercises":
        return true;
      default:
        return false;
    }
  };

  const Container = styled("div")(({ theme }) => ({
    position: "relative",
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(243,242,255,1)"
  }));

  const classes = useStyles();

  const getAttendeeDetail = async () => {
    console.log("attendee id > ", params.attendeeId);
    history.push(`/room/${params.attendeeId}`);
  };

  const getDate = () => {
    if (
      sessionInformation.start_date === null ||
      sessionInformation.start_time === null
    ) {
      return "";
    }

    var startDate = moment(
      `${sessionInformation.start_date} ${sessionInformation.start_time}:00`,
      "YYYY-MM-DD HH:mm:ss"
    );
    return startDate.format("DD MMMM YYYY HH:mm");
  };

  const getSessionTime = () => {
    if (
      sessionInformation.start_date === null ||
      sessionInformation.start_time === null
    ) {
      return "";
    }

    var startDate = moment(
      `${sessionInformation.start_date} ${sessionInformation.start_time}:00`,
      "YYYY-MM-DD HH:mm:ss"
    );
    var endDate = moment(
      `${sessionInformation.start_date} ${sessionInformation.end_time}:00`,
      "YYYY-MM-DD HH:mm:ss"
    );
    // calculate total duration
    var duration = moment.duration(endDate.diff(startDate));

    // duration in hours

    var hours = duration.hours();

    // duration in minutes
    var minutes = duration.minutes() % 60;

    var minutesText = `${minutes} minutes`;
    var hourText = hours === 0 ? "" : `${hours} hours and `;

    return `${hourText}${minutesText}`;
  };

  const getImage = () => {
    if (sessionInformation.img_url === null) {
      return "";
    }

    if (sessionInformation.img_url.startsWith("http")) {
      return sessionInformation.img_url;
    } else {
      return `${amazonEndpoint}/${sessionInformation.img_url}.jpg`;
    }
  };

  const sessionReadMoreClicked = () => {
    if (readMoreContentDetail === "COLLAPSED") {
      setReadMoreContentDetail("UNCOLLAPSED");
    } else if (readMoreContentDetail === "UNCOLLAPSED") {
      setReadMoreContentDetail("COLLAPSED");
    }
  };

  const userReadMoreClicked = () => {
    if (readMoreUserDetail === "COLLAPSED") {
      setReadMoreUserDetail("UNCOLLAPSED");
    } else if (readMoreUserDetail === "UNCOLLAPSED") {
      setReadMoreUserDetail("COLLAPSED");
    }
  };

  const renderSessionDetail = () => {
    if (readMoreContentDetail === "IDLE") {
      return <>{sessionInformation.detail}</>;
    } else if (readMoreContentDetail === "COLLAPSED") {
      return (
        <>
          {sessionInformation.detail?.substr(0, readMoreLength)}...
          <div
            onClick={sessionReadMoreClicked}
            className={classes.readMoreTitle}
          >
            Read more
          </div>
        </>
      );
    } else if (readMoreContentDetail === "UNCOLLAPSED") {
      return (
        <>
          {sessionInformation.detail}
          <div
            onClick={sessionReadMoreClicked}
            className={classes.readMoreTitle}
          >
            Read more
          </div>
        </>
      );
    } else {
      return null;
    }
  };

  const renderInfBio = () => {
    if (readMoreUserDetail === "IDLE") {
      return <>{sessionInformation.inf_bio}</>;
    } else if (readMoreUserDetail === "COLLAPSED") {
      return (
        <>
          {sessionInformation.inf_bio?.substr(0, readMoreLength)}...
          <div onClick={userReadMoreClicked} className={classes.readMoreBio}>
            Read more
          </div>
        </>
      );
    } else if (readMoreUserDetail === "UNCOLLAPSED") {
      return (
        <>
          {sessionInformation.inf_bio}
          <div onClick={userReadMoreClicked} className={classes.readMoreBio}>
            Read more
          </div>
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <Container>
      <div className={classes.subContainer}>
        <div className={classes.imageContainer}>
          <img src={getImage()} className={classes.imageContent} />
        </div>
        <div className={classes.contentContainer}>
          <div className={classes.contentDetailContainer}>
            <div className={classes.contentTitleSubtitleContainer}>
              <span className={classes.contentTitle}>
                {sessionInformation.name}
              </span>
              <span className={classes.contentSubtitle}>
                {renderSessionDetail()}
              </span>
            </div>

            <div
              className={classes.subtitleContainerBackground}
              style={{ backgroundColor: "rgba(10,23,105,1)" }}
            >
              <span className={classes.nameTitle}>
                {sessionInformation.inf_name}
              </span>
              <span className={classes.nameDescription}>{renderInfBio()}</span>

              <div className={classes.dateDetailContainer}>
                <img src={dateIcon} className={classes.dateIcon} />
                <p className={classes.dateTitle}>{getDate()}</p>
              </div>
              <div className={classes.timeDetailContainer}>
                <img src={timeIcon} className={classes.timeIcon} />
                <p className={classes.timeTitle}>{getSessionTime()}</p>
              </div>
              <div className={classes.domainDetailContainer}>
                <img src={worldIcon} className={classes.domainIcon} />
                <p className={classes.domainTitle}>
                  {sessionInformation.inf_url}
                </p>
              </div>
              <div className={classes.contactDetailContainer}>
                <img src={emailIcon} className={classes.contactImage} />
                <p
                  className={classes.contactTitle}
                >{`Contact ${sessionInformation.inf_name}`}</p>
              </div>
            </div>
          </div>
          <div
            className={classes.joinSessionContainer}
            style={{ cursor: "pointer" }}
            onClick={getAttendeeDetail}
          >
            <p className={classes.joinSessionTitle}>Enter Room</p>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default SessionSuccess;
