import React, { useState, useEffect } from "react";
import { CircularProgress } from "@material-ui/core";
import {
  makeStyles,
  Theme,
  styled,
  createStyles
} from "@material-ui/core/styles";
import { useLocation, useHistory, useParams } from "react-router-dom";
import moment from "moment";

import userImage from "../../images/womanDrawingIPadPhoto.jpg";
import notchImage from "../../images/whiteContainerPathX.png";
import { SessionInformation } from "./model";
import { endpoint, amazonEndpoint } from "../../network/constants";
import dateIcon from "../../images/dateIcon.png";
import timeIcon from "../../images/timeIcon.png";
import emailIcon from "../../images/emailIcon.png";
import worldIcon from "../../images/worldIcon.png";

import { fetchSession } from "../../network";
import axios from "axios";

import "../../App.css";
import "../../css/normalize.css";
import "../../css/global.css";

import theme from "../../theme";

const ConnectAccountSuccess = (props: any) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      container: {
        backgroundColor: theme.palette.background.default
      }
    })
  );

  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    onboardUserSuccess();
  }, []);

  const Container = styled("div")(({ theme }) => ({
    position: "relative",
    height: "100vh",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(243,242,255,1)"
  }));

  const classes = useStyles();

  const onboardUserSuccess = async () => {
    const webpayendpoint = `${endpoint}/account/onboard-user-success`;
    var query = new URLSearchParams(location.search);
    setLoading(true);

    axios
      .post(webpayendpoint, {
        area: query.get("area"),
        phone: query.get("phone")
      })
      .then(rs => {
        const { data } = rs;
        window.location.href = "finished-binding";
        //history.push(`/finished-binding`);
      })
      .catch(err => {
        console.log("err > ", err);
        setLoading(false);
      })
      .finally(() => {});
  };

  return (
    <Container>
      <div className="sr-root">
        <div className="sr-main">
          <header className="sr-header">
            <div className="sr-header__logo"></div>
          </header>

          <div className="sr-payment-summary payment-view">
            <h1 className="order-amount">
              Thank you for connection your account
            </h1>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ConnectAccountSuccess;
