import React, { useState, useEffect, useRef } from "react";
import { CircularProgress } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { useLocation, useHistory, useParams } from "react-router-dom";
import moment from "moment";

import { endpoint, amazonEndpoint } from "../../network/constants";

import dateIcon from "../../images/dateIcon.png";
import timeIcon from "../../images/timeIcon.png";
import emailIcon from "../../images/emailIcon.png";
import worldIcon from "../../images/worldIcon.png";
import { SessionInformation } from "./model";

import { fetchSession } from "../../network";
import axios from "axios";

import "../../App.css";

interface RouteParamsConfirmOrder {
  sessionId: string;
  attendeeId: string;
}

const PaymentWaiting = (props: any) => {
  const useStyles = makeStyles(theme =>
    createStyles({
      container: {
        color: "black",
        backgroundColor: theme.palette.background.default
      },
      subContainer: {
        display: "flex",

        flex: 1,
        backgroundColor: "rgba(243,242,255,1)",
        justifyContent: "center",
        alignSelf: "center",
        flexDirection: "row",
        [theme.breakpoints.down("sm")]: {
          flexDirection: "column"
        }
      },
      imageContainer: {
        height: 340,
        width: 340,
        display: "flex",
        flexDirection: "column",
        marginRight: 20,
        overflow: "hidden",
        borderRadius: 27,
        [theme.breakpoints.down("sm")]: {
          display: "none"
        },
        backgroundColor: "rgba(31, 23, 105, 0.92)"
      },
      imageContent: {
        width: 340,
        height: 340
      },
      contentContainer: {
        display: "flex",
        // backgroundColor: "clear",
        // backgroundColor: "gray",
        width: 500,
        height: 720,
        flexDirection: "column",
        alignSelf: "center",
        [theme.breakpoints.down("sm")]: {
          // backgroundColor: "red",
        },
        [theme.breakpoints.down("xs")]: {
          width: "90%"
        }
      },
      contentDetailContainer: {
        display: "flex",
        width: 500,
        height: 550,
        alignItems: "center",
        backgroundColor: "white",
        //backgroundColor: "red",
        borderRadius: 55,
        flexDirection: "column",
        [theme.breakpoints.down("xs")]: {
          width: "90%"
        }
      },
      notchImage: {
        width: "100%",
        height: 341,
        marginTop: -6
      },
      contentTitle: {
        padding: 0,
        margin: 0,
        marginTop: 33,
        marginLeft: 40,
        fontSize: 26,
        color: "rgba(17,16,32,0.8)",
        fontFamily: "IBMPlexSans",
        fontWeight: "bold",
        [theme.breakpoints.down("xs")]: {
          marginLeft: 15,
          fontSize: 22
        }
      },
      contentSubtitle: {
        margin: 0,
        padding: 0,
        marginTop: 9,
        width: 380,
        marginLeft: 40,
        fontSize: 20,
        color: "rgba(17,16,32,0.8)",
        fontFamily: "IBMPlexSans",
        [theme.breakpoints.down("xs")]: {
          marginLeft: 15,
          width: "70%"
        }
      },
      nameTitle: {
        margin: 0,
        padding: 0,
        //marginTop: -50,
        marginLeft: 40,
        // color: "black",
        color: "black",
        fontSize: 20,
        fontFamily: "IBMPlexSans",
        fontWeight: "bold",
        [theme.breakpoints.down("xs")]: {
          marginTop: -70
        }
      },
      nameDescription: {
        margin: 0,
        padding: 0,
        marginTop: 1,
        marginLeft: 40,
        color: "rgba(210,208,225,1)",
        fontSize: 17,
        letterSpacing: 0.4,
        width: 380,
        fontFamily: "IBMPlexSans",
        [theme.breakpoints.down("xs")]: {
          marginLeft: 35,
          width: "80%"
        }
      },
      dateDetailContainer: {
        display: "flex",
        height: 22,
        marginTop: 17,
        alignItems: "center"
      },
      dateIcon: {
        width: 17,
        height: 19,
        marginLeft: 40
      },
      dateTitle: {
        padding: 0,
        margin: 0,
        color: "black",
        marginLeft: 20,
        fontFamily: "IBMPlexSans",
        fontWeight: "normal"
      },
      timeDetailContainer: {
        display: "flex",
        height: 22,
        marginTop: 18,
        alignItems: "center"
      },
      timeIcon: {
        width: 12,
        height: 18,
        marginLeft: 43
      },
      timeTitle: {
        padding: 0,
        margin: 0,
        color: "black",
        marginLeft: 21,
        fontFamily: "IBMPlexSans",
        fontWeight: "normal"
      },
      domainDetailContainer: {
        display: "flex",
        height: 22,
        marginTop: 18,
        alignItems: "center"
      },
      domainIcon: {
        width: 18,
        height: 18,
        marginLeft: 40
      },
      domainTitle: {
        padding: 0,
        margin: 0,
        color: "black",
        marginLeft: 18,
        fontFamily: "IBMPlexSans",
        fontWeight: "normal"
      },
      contactDetailContainer: {
        display: "flex",
        height: 22,
        marginTop: 19,
        alignItems: "center"
      },
      contactImage: {
        width: 18,
        height: 14,
        marginLeft: 40
      },
      contactTitle: {
        padding: 0,
        margin: 0,
        color: "black",
        marginLeft: 18,
        fontFamily: "IBMPlexSans",
        fontWeight: "normal"
      },
      joinSessionContainer: {
        display: "flex",
        height: 55,
        width: "80%",
        backgroundColor: "rgba(255,170,119,1)",
        alignSelf: "center",
        marginTop: 20,
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        borderRadius: 27,
        [theme.breakpoints.down("xs")]: {
          width: "90%",
          alignItems: "center",
          alignSelf: "auto"
        }
      },
      joinSessionTitle: {
        fontFamily: "IBMPlexSans",
        fontWeight: "bold",
        fontSize: 20,
        color: "black"
      },
      sessionDetailContainer: {
        alignSelf: "center",
        marginTop: 15
      },
      sessionDetailTitle: {
        margin: 0,
        padding: 0,
        color: "rgba(17,16,32,1)",
        fontFamily: "IBMPlexSans",
        fontStyle: "italic"
      }
    })
  );

  const [isLoading, setLoading] = useState(true);
  const history = useHistory();
  const location = useLocation();
  const [sessionKey, setSessionKey] = useState("");
  const [sessionInformation, setSessionInformation] = useState<
    SessionInformation
  >({
    session_id: null,
    user_id: null,
    start_date: null,
    start_time: null,
    end_time: null,
    img_url: null,
    name: null,
    detail: null,
    price: null,
    inf_name: null,
    inf_url: null,
    inf_bio: null
  });

  const params = useParams<RouteParamsConfirmOrder>();

  useEffect(() => {
    async function fetchSessionFunction(sessionKey: String) {
      fetchSession(sessionKey)
        .then(result => {
          console.log("result > ", result);
          setSessionInformation(result.data.session);
        })
        .catch(error => {
          console.log("error > ", error);
        });
    }

    setSessionKey(params.sessionId);
    setLoading(false);
    fetchSessionFunction(params.sessionId);

    async function checkPayment() {
      const webpayendpoint = `${endpoint}/account/payment-success`;
      const payload = {
        attendeeId: params.attendeeId,
        sessionId: params.sessionId
      };
      axios
        .post(webpayendpoint, payload)
        .then(rs => {
          const { data } = rs;
          const { attendeeId } = data;
          history.push(`/nickname/${attendeeId}`);
        })
        .catch(err => {
          console.log("err > ", err);
        });
    }

    checkPayment();
  }, []);

  const classes = useStyles();

  const getDate = () => {
    if (
      sessionInformation.start_date === null ||
      sessionInformation.start_time === null
    ) {
      return "";
    }

    var startDate = moment(
      `${sessionInformation.start_date} ${sessionInformation.start_time}:00`,
      "YYYY-MM-DD HH:mm:ss"
    );
    return startDate.format("DD MMMM YYYY HH:mm");
  };

  const getSessionTime = () => {
    if (
      sessionInformation.start_date === null ||
      sessionInformation.start_time === null
    ) {
      return "";
    }

    var startDate = moment(
      `${sessionInformation.start_date} ${sessionInformation.start_time}:00`,
      "YYYY-MM-DD HH:mm:ss"
    );
    var endDate = moment(
      `${sessionInformation.start_date} ${sessionInformation.end_time}:00`,
      "YYYY-MM-DD HH:mm:ss"
    );
    // calculate total duration
    var duration = moment.duration(endDate.diff(startDate));

    // duration in hours

    var hours = duration.hours();

    // duration in minutes
    var minutes = duration.minutes() % 60;

    var minutesText = `${minutes} minutes`;
    var hourText = hours === 0 ? "" : `${hours} hours and `;

    return `${hourText}${minutesText}`;
  };

  return (
    <div
      style={{
        position: "relative",
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(243,242,255,1)"
      }}
    >
      <div className={classes.subContainer}>
        <div className={classes.imageContainer}>
          <div style={{ display: "flex" }}>
            <p
              className={classes.contentTitle}
              style={{ color: "rgb(255,255,255)" }}
            >
              {sessionInformation.name}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              height: "100%",
              flexDirection: "column",
              justifyContent: "center"
            }}
          >
            <div className={classes.dateDetailContainer}>
              <img src={dateIcon} className={classes.dateIcon} />
              <p
                className={classes.dateTitle}
                style={{ color: "rgb(255,255,255)" }}
              >
                {getDate()}
              </p>
            </div>
            <div className={classes.timeDetailContainer}>
              <img src={timeIcon} className={classes.timeIcon} />
              <p
                className={classes.timeTitle}
                style={{ color: "rgb(255,255,255)" }}
              >
                {getSessionTime()}
              </p>
            </div>
            <div className={classes.domainDetailContainer}>
              <img src={worldIcon} className={classes.domainIcon} />
              <p
                className={classes.domainTitle}
                style={{ color: "rgb(255,255,255)" }}
              >
                {sessionInformation.inf_url}
              </p>
            </div>
            <div className={classes.contactDetailContainer}>
              <img src={emailIcon} className={classes.contactImage} />
              <p
                className={classes.contactTitle}
                style={{ color: "rgb(255,255,255)" }}
              >{`Contact ${sessionInformation.inf_name}`}</p>
            </div>
          </div>
        </div>
        <div className={classes.contentContainer}>
          <div className={classes.contentDetailContainer}>
            <p className={classes.contentTitle} style={{ marginLeft: "0px" }}>
              We are checking your confirmation
            </p>
            <CircularProgress style={{ color: "black", marginTop: "10px" }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentWaiting;
