import axios from "axios";
import { endpoint } from "./constants";

const fetchSession = async (sessionKey: String) => {
  //http://localhost:3161/session/get-room
  // let endpoint = "http://localhost:3161/session/get-room";
  const shareUrl = window.location.href;
  const payload = { shareUrl };

  return axios.get(`${endpoint}/session/${sessionKey}`);
};

const fetchRoomDetail = async (roomKey: String) => {
  return "";
};

export { endpoint, fetchSession, fetchRoomDetail };
