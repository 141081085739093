import React from "react";
import {makeStyles, Theme, styled, createStyles} from "@material-ui/core/styles";
import {useLocation, Redirect, withRouter, useHistory} from "react-router-dom";
import {endpoint} from "../../network/constants";
// MARK: - images
import dateIcon from "../../images/dateIcon.png";
import timeIcon from "../../images/timeIcon.png";
import emailIcon from "../../images/emailIcon.png";
import worldIcon from "../../images/worldIcon.png";
import userImage from "../../images/womanDrawingIPadPhoto.jpg";
import notchImage from "../../images/whiteContainerPathX.png";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.background.default,
    },
    subContainer: {
      display: "flex",
      flex: 1,
      backgroundColor: "rgba(243,242,255,1)",
      justifyContent: "center",
      alignSelf: "center",
      flexDirection: "row",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    imageContainer: {
      height: 340,
      width: 340,
      marginRight: 20,
      overflow: "hidden",
      borderRadius: 27,
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    imageContent: {
      width: 340,
      height: 340,
    },
    contentContainer: {
      display: "flex",
      backgroundColor: "clear",
      width: 500,
      height: 720,
      flexDirection: "column",
      alignSelf: "center",
      [theme.breakpoints.down("sm")]: {
        // backgroundColor: "red",
      },
      [theme.breakpoints.down("xs")]: {
        width: "90%",
      },
    },
    contentDetailContainer: {
      display: "flex",
      width: 500,
      height: 550,
      backgroundColor: "rgba(10,23,105,1)",
      borderRadius: 55,
      flexDirection: "column",
      [theme.breakpoints.down("xs")]: {
        width: "90%",
      },
    },
    notchImage: {
      width: "100%",
      height: 341,
      marginTop: -6,
    },
    contentTitle: {
      position: "absolute",
      marginLeft: 40,
      marginTop: 32,
      fontSize: 26,
      fontFamily: "IBMPlexSans",
      [theme.breakpoints.down("xs")]: {
        marginLeft: 15,
        fontSize: 22,
      },
    },
    contentSubtitle: {
      position: "absolute",
      width: 380,
      marginTop: 74,
      marginLeft: 40,
      color: "rgba(17,16,32,0.8)",
      fontFamily: "IBMPlexSans",
      [theme.breakpoints.down("xs")]: {
        marginLeft: 15,
        width: "70%",
      },
    },
    nameTitle: {
      margin: 0,
      padding: 0,
      marginTop: -50,
      marginLeft: 40,
      color: "white",
      fontSize: 20,
      fontFamily: "IBMPlexSans",
      fontWeight: "bold",
      [theme.breakpoints.down("xs")]: {
        marginTop: -70,
      },
    },
    nameDescription: {
      margin: 0,
      padding: 0,
      marginTop: 1,
      marginLeft: 40,
      color: "rgba(210,208,225,1)",
      fontSize: 17,
      letterSpacing: 0.4,
      width: 380,
      fontFamily: "IBMPlexSans",
      [theme.breakpoints.down("xs")]: {
        marginLeft: 35,
        width: "80%",
      },
    },
    dateDetailContainer: {
      display: "flex",
      height: 22,
      marginTop: 17,
      alignItems: "center",
    },
    dateIcon: {
      width: 17,
      height: 19,
      marginLeft: 40,
    },
    dateTitle: {
      padding: 0,
      margin: 0,
      color: "white",
      marginLeft: 20,
      fontFamily: "IBMPlexSans",
      fontWeight: "normal",
    },
    timeDetailContainer: {
      display: "flex",
      height: 22,
      marginTop: 18,
      alignItems: "center",
    },
    timeIcon: {
      width: 12,
      height: 18,
      marginLeft: 43,
    },
    timeTitle: {
      padding: 0,
      margin: 0,
      color: "white",
      marginLeft: 21,
      fontFamily: "IBMPlexSans",
      fontWeight: "normal",
    },
    domainDetailContainer: {
      display: "flex",
      height: 22,
      marginTop: 18,
      alignItems: "center",
    },
    domainIcon: {
      width: 18,
      height: 18,
      marginLeft: 40,
    },
    domainTitle: {
      padding: 0,
      margin: 0,
      color: "white",
      marginLeft: 18,
      fontFamily: "IBMPlexSans",
      fontWeight: "normal",
    },
    contactDetailContainer: {
      display: "flex",
      height: 22,
      marginTop: 19,
      alignItems: "center",
    },
    contactImage: {
      width: 18,
      height: 14,
      marginLeft: 40,
    },
    contactTitle: {
      padding: 0,
      margin: 0,
      color: "white",
      marginLeft: 18,
      fontFamily: "IBMPlexSans",
      fontWeight: "normal",
    },
    joinSessionContainer: {
      display: "flex",
      height: 55,
      width: "80%",
      //   backgroundColor: "rgba(255,170,119,1)",
      backgroundColor: "rgba(223,221,238,1)",
      alignSelf: "center",
      marginTop: 20,
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 27,
      [theme.breakpoints.down("xs")]: {
        width: "90%",
        alignItems: "center",
        alignSelf: "auto",
      },
    },
    joinSessionTitle: {
      fontFamily: "IBMPlexSans",
      fontWeight: "bold",
      fontSize: 20,
    },
    sessionDetailContainer: {
      alignSelf: "center",
      marginTop: 15,
    },
    sessionDetailTitle: {
      margin: 0,
      padding: 0,
      color: "rgba(17,16,32,1)",
      fontFamily: "IBMPlexSans",
      fontStyle: "italic",
    },
  }),
);

const EnterRoom = (props: any) => {
  const [sessionId, setSessionId] = React.useState("");
  const [sessionKey, setSessionKey] = React.useState("");
  const [sessionInformation, setSessionInformation] = React.useState({});

  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const Container = styled("div")(({theme}) => ({
    position: "relative",
    height: "100vh",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(243,242,255,1)",
  }));

  const uuidEmit = () => {
    // Get now time
    const n = Date.now();
    // Generate random
    const r = Math.random();
    // Stringify now time and generate additional random number
    const s = String(n) + String(~~(r * 9e4) + 1e4);
    // Form UUID and return it
    return `${s.slice(0, 8)}-${s.slice(8, 12)}-4${s.slice(12, 15)}-${[8, 9, "a", "b"][~~(r * 3)]}${s.slice(15, 18)}-${s.slice(s.length - 12)}`;
  };

  React.useEffect(() => {}, []);

  return (
    <Container>
      <div className={classes.subContainer}>
        <div className={classes.imageContainer}>
          <img src={userImage} className={classes.imageContent} />
        </div>
        <div className={classes.contentContainer}>
          <div className={classes.contentDetailContainer}>
            <img src={notchImage} className={classes.notchImage} />
            <p className={classes.contentTitle}>Tips for drawing with iPad</p>
            <p className={classes.contentSubtitle}>
              Suspendisse felis erat, auctor ut eleifend vel, blandit quis ligula. Suspendisse id tortor mollis. Vivamus id justo tellus. Donec a velit lobortis, cursus eros ut, bibendum nisl. Read
              more
            </p>
            <p className={classes.nameTitle}>Stella Jongsonn</p>
            <p className={classes.nameDescription}>Nunc eget neque dictum, placerat ligula eu, rutrum turpis. Read more </p>

            <div className={classes.dateDetailContainer}>
              <img src={dateIcon} className={classes.dateIcon} />
              <p className={classes.dateTitle}>01 Agust 2020 13:20</p>
            </div>
            <div className={classes.timeDetailContainer}>
              <img src={timeIcon} className={classes.timeIcon} />
              <p className={classes.timeTitle}>1 Hour 20 Minutes</p>
            </div>
            <div className={classes.domainDetailContainer}>
              <img src={worldIcon} className={classes.domainIcon} />
              <p className={classes.domainTitle}>www.stelladesigner.com</p>
            </div>
            <div className={classes.contactDetailContainer}>
              <img src={emailIcon} className={classes.contactImage} />
              <p className={classes.contactTitle}>Contact Stella</p>
            </div>
          </div>
          <div
            className={classes.joinSessionContainer}
            style={{cursor: "pointer"}}
            onClick={(e) => {
              e.preventDefault();
              //    console.log("session informat > ", sessionInformation);
              //    console.log("CLİCKED TO THE BUTTON");
              //    console.log("session key > ", sessionKey);
              //history.push(`/payment/${sessionKey}`, {sessionKey});
            }}
          >
            <p className={classes.joinSessionTitle}>Enter the Room</p>
          </div>
          <div className={classes.sessionDetailContainer}>
            <p className={classes.sessionDetailTitle}>Session price is $2.99 per participant</p>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default EnterRoom;
