import React from 'react';
import Participant from '../Participant/Participant';
import { styled } from '@material-ui/core/styles';
import useParticipants from '../../hooks/useParticipants/useParticipants';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import useSelectedParticipant from '../VideoProvider/useSelectedParticipant/useSelectedParticipant';

const Container = styled('aside')(({ theme }) => ({
  padding: '0.5em',
  width: '100%',
  height: '100%',
  flexGrow: 1,
  flexBasis: '0px',
}));

const ScrollContainer = styled('div')(({ theme }) => ({
  flexGrow: 1,
  flexBasis: '0px',
  flexDirection: 'row',
  width: '100%',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  justifyItems: 'center',
}));

export default function ParticipantStrip() {
  const {
    room: { localParticipant },
  } = useVideoContext();
  const participants = useParticipants();
  const [selectedParticipant, setSelectedParticipant] = useSelectedParticipant();

  return (
    <Container>
      <ScrollContainer style={{}}>
    
          <Participant
            participant={localParticipant}
            isSelected={true}
            participantCount={participants.length + 1}
            onClick={() => setSelectedParticipant(localParticipant)}
          />
          {participants.map((participant, index) => {

            return <Participant
              key={participant.sid}
              participant={participant}
              isSelected={true}
              participantCount={participants.length + 1}
              onClick={() => setSelectedParticipant(participant)}
            />
          })}

          
      </ScrollContainer>
    </Container>
  );
}
