import React from "react";
import ParticipantStrip from "../ParticipantStrip/ParticipantStrip";
import { styled } from "@material-ui/core/styles";
import MainParticipant from "../MainParticipant/MainParticipant";

const Container = styled("div")(({ theme }) => ({
  position: "relative",
  height: "100%",
  display: "flex",
  width: "100%",
  flexGrow: 1,
  flexBasis: "0px"
  // gridTemplateColumns: `${theme.sidebarWidth}px 1fr`,
  // gridTemplateAreas: '". participantList"',
  // [theme.breakpoints.down('xs')]: {
  //   gridTemplateAreas: '"participantList" "."',
  //   gridTemplateColumns: `auto`,
  //   gridTemplateRows: `calc(100% - ${theme.sidebarMobileHeight + 12}px) ${theme.sidebarMobileHeight + 6}px`,
  //   gridGap: '6px',
  // },
}));

export default function Room() {
  return (
    <Container>
      <ParticipantStrip />
      <MainParticipant />
    </Container>
  );
}
