import React, { useState, useEffect, useRef } from "react";
import { CircularProgress } from "@material-ui/core";
import {
  makeStyles,
  Theme,
  styled,
  createStyles
} from "@material-ui/core/styles";
import { useLocation, useHistory, useParams } from "react-router-dom";
import moment from "moment";
import Input from "@material-ui/core/Input";
import IconButton from "@material-ui/core/IconButton";

import { endpoint, amazonEndpoint, webEndpoint } from "../../network/constants";
import { TextField } from "@material-ui/core";
import PartyPopper from "../../images/partyPopper.png";
import WarningIcon from "../../images/warningIcon.png";

import dateIcon from "../../images/dateIcon.svg";
import tickIcon from "../../images/checkmarkIcon.svg";
import timeIcon from "../../images/timeIcon.svg";
import emailIcon from "../../images/emailIcon.svg";
import worldIcon from "../../images/worldIcon.svg";

import axios from "axios";

import "../../App.css";

const SetNickname = props => {
  const useStyles = makeStyles(theme =>
    createStyles({
      container: {
        color: "black",
        backgroundColor: theme.palette.background.default
      },
      subContainer: {
        display: "flex",

        flex: 1,
        backgroundColor: "rgba(243,242,255,1)",
        justifyContent: "center",
        alignSelf: "center",
        flexDirection: "row",
        [theme.breakpoints.down("sm")]: {
          flexDirection: "column"
        }
      },
      imageContainer: {
        height: "100%",
        width: 340,
        marginRight: 20,
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        overflow: "hidden",
        flexDirection: "column",
        borderRadius: 55,
        backgroundColor: "rgba(31, 23, 105, 0.92)",
        [theme.breakpoints.down("sm")]: {
          display: "none"
        },
        paddingBottom: 30
      },
      imageContent: {
        width: 340
      },
      contentContainer: {
        display: "flex",
        width: 500,
        flexDirection: "column",
        alignSelf: "center",
        [theme.breakpoints.down("sm")]: {
          // backgroundColor: "red",
        },
        [theme.breakpoints.down("xs")]: {
          width: "90%"
        }
      },
      contentDetailContainer: {
        display: "flex",
        width: 500,
        backgroundColor: "white",
        //backgroundColor: "red",
        borderRadius: 55,
        flexDirection: "column",
        [theme.breakpoints.down("xs")]: {
          width: "90%"
        }
      },
      notchImage: {
        width: "100%",
        height: 341,
        marginTop: -6
      },
      contentTitle: {
        padding: 0,
        margin: 0,
        marginTop: 33,
        marginLeft: 40,
        fontSize: 26,
        fontFamily: "IBMPlexSans-Medium",
        [theme.breakpoints.down("xs")]: {
          marginLeft: 15,
          fontSize: 22
        }
      },
      contentSubtitle: {
        margin: 0,
        padding: 0,
        marginTop: 9,
        width: 380,
        marginLeft: 40,
        fontSize: 20,
        color: "rgba(17,16,32,0.8)",
        fontFamily: "IBMPlexSans",
        [theme.breakpoints.down("xs")]: {
          marginLeft: 15,
          width: "70%"
        }
      },
      nameTitle: {
        margin: 0,
        padding: 0,
        //marginTop: -50,
        marginLeft: 40,
        // color: "black",
        color: "black",
        fontSize: 20,
        fontFamily: "IBMPlexSans",
        fontWeight: "bold",
        [theme.breakpoints.down("xs")]: {
          marginTop: -70
        }
      },
      nameDescription: {
        margin: 0,
        padding: 0,
        marginTop: 1,
        marginLeft: 40,
        color: "rgba(210,208,225,1)",
        fontSize: 17,
        letterSpacing: 0.4,
        width: 380,
        fontFamily: "IBMPlexSans",
        [theme.breakpoints.down("xs")]: {
          marginLeft: 35,
          width: "80%"
        }
      },
      dateDetailContainer: {
        display: "flex",
        height: 22,
        marginTop: 17,
        alignItems: "center"
      },
      dateIcon: {
        width: 17,
        height: 19,
        marginLeft: 40
      },
      dateTitle: {
        padding: 0,
        margin: 0,
        color: "black",
        marginLeft: 20,
        fontFamily: "IBMPlexSans-Medium",
        fontSize: 17
      },
      timeDetailContainer: {
        display: "flex",
        height: 22,
        marginTop: 18,
        alignItems: "center"
      },
      timeIcon: {
        width: 12,
        height: 18,
        marginLeft: 43
      },
      timeTitle: {
        padding: 0,
        margin: 0,
        color: "black",
        marginLeft: 21,
        fontFamily: "IBMPlexSans-Medium",
        fontSize: 17
      },
      domainDetailContainer: {
        display: "flex",
        minHeight: 22,
        marginTop: 18,
        alignItems: "center"
      },
      domainIcon: {
        width: 18,
        height: 18,
        marginLeft: 40
      },
      domainTitle: {
        padding: 0,
        margin: 0,
        color: "black",
        marginLeft: 18,
        fontFamily: "IBMPlexSans-Medium",
        fontSize: 17
      },
      contactDetailContainer: {
        display: "flex",
        height: 22,
        marginTop: 19,
        alignItems: "center"
      },
      contactImage: {
        width: 18,
        height: 14,
        marginLeft: 40
      },
      contactTitle: {
        padding: 0,
        margin: 0,
        color: "black",
        marginLeft: 18,
        fontFamily: "IBMPlexSans-Medium",
        fontSize: 17
      },
      joinSessionContainer: {
        display: "flex",
        height: 55,
        width: "80%",
        backgroundColor: "rgba(255,170,119,1)",
        alignSelf: "center",
        marginTop: 20,
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        borderRadius: 27,
        [theme.breakpoints.down("xs")]: {
          width: "90%",
          alignItems: "center",
          alignSelf: "auto"
        }
      },
      joinSessionTitle: {
        fontFamily: "IBMPlexSans",
        fontWeight: "bold",
        fontSize: 20,
        color: "black"
      },
      sessionDetailContainer: {
        alignSelf: "center",
        marginTop: 15
      },
      sessionDetailTitle: {
        margin: 0,
        padding: 0,
        color: "rgba(17,16,32,1)",
        fontFamily: "IBMPlexSans",
        fontStyle: "italic"
      },
      inputFocusClearOutline: {
        "&:focus": {
          outline: "none",
          border: "none",
          backgroundColor: "none"
        },
        "&::placeholder": {
          fontSize: 20,
          color: "rgba(31, 23, 105, 0.3)",
          fontFamily: "IBMPlexSans"
        }
      }
    })
  );

  const [isLoading, setLoading] = useState(true);
  const history = useHistory();
  const location = useLocation();

  const [sessionInformation, setSessionInformation] = useState({});
  const [nickNameUpdated, setNicknameUpdated] = useState(false);
  const [nickName, setNickName] = useState("");

  const [videoRoomUrl, setVideoRoomUrl] = useState("");

  const textAreaRef = useRef(null);

  const params = useParams();

  useEffect(() => {
    console.log("location", location.pathname);
    setVideoRoomUrl(`${webEndpoint}/session-success/${params.attendeeId}`);

    async function fetchSessionByAttendeeFunction(attendeeId) {
      const simplend = `${endpoint}/session/detail-by-attendee-id/${attendeeId}`;

      axios
        .get(simplend)
        .then(rs => {
          const { data } = rs;
          console.log("dat > ", data);
          const { session } = data.result;
          setSessionInformation(session);
        })
        .catch(err => {
          console.log("err > ", err);
        });
    }

    setLoading(false);
    fetchSessionByAttendeeFunction(params.attendeeId);
  }, [location.pathname, params.attendeeId]);

  const classes = useStyles();

  const setRandomNickname = () => {
    const simplend = `${endpoint}/session/update-nickname`;
    const payload = { attendeeId: params.attendeeId, nickname: nickName };

    console.log("payload > ", payload);

    axios
      .post(simplend, payload)
      .then(rs => {
        const { data } = rs;
        setNicknameUpdated(true);
      })
      .catch(err => {
        console.log("err > ", err);
      });
  };

  const getDate = () => {
    if (
      sessionInformation.start_date === null ||
      sessionInformation.start_time === null
    ) {
      return "";
    }

    var startDate = moment(
      `${sessionInformation.start_date} ${sessionInformation.start_time}:00`,
      "YYYY-MM-DD HH:mm:ss"
    );
    return startDate.format("DD MMMM YYYY HH:mm");
  };

  const getSessionTime = () => {
    if (
      sessionInformation.start_date === null ||
      sessionInformation.start_time === null
    ) {
      return "";
    }

    var startDate = moment(
      `${sessionInformation.start_date} ${sessionInformation.start_time}:00`,
      "YYYY-MM-DD HH:mm:ss"
    );
    var endDate = moment(
      `${sessionInformation.start_date} ${sessionInformation.end_time}:00`,
      "YYYY-MM-DD HH:mm:ss"
    );
    // calculate total duration
    var duration = moment.duration(endDate.diff(startDate));

    // duration in hours

    var hours = duration.hours();

    // duration in minutes
    var minutes = duration.minutes() % 60;

    var minutesText = `${minutes} minutes`;
    var hourText = hours === 0 ? "" : `${hours} hours and `;

    return `${hourText}${minutesText}`;
  };

  const getImage = () => {
    if (!sessionInformation.img_url) {
      return "";
    }

    if (sessionInformation.img_url.startsWith("http")) {
      return sessionInformation.img_url;
    } else {
      return `${amazonEndpoint}/${sessionInformation.img_url}.jpg`;
    }
  };

  return (
    <div
      style={{
        position: "relative",
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(243,242,255,1)"
      }}
    >
      <div className={classes.subContainer}>
        <div className={classes.imageContainer}>
          <div style={{ display: "flex" }}>
            <p
              className={classes.contentTitle}
              style={{ color: "rgb(255,255,255)" }}
            >
              {sessionInformation.name}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              height: "100%",
              flexDirection: "column",
              justifyContent: "center"
            }}
          >
            <div className={classes.dateDetailContainer}>
              <img src={dateIcon} className={classes.dateIcon} />
              <p
                className={classes.dateTitle}
                style={{ color: "rgb(255,255,255)" }}
              >
                {getDate()}
              </p>
            </div>
            <div className={classes.timeDetailContainer}>
              <img src={timeIcon} className={classes.timeIcon} />
              <p
                className={classes.timeTitle}
                style={{ color: "rgb(255,255,255)" }}
              >
                {getSessionTime()}
              </p>
            </div>
            <div className={classes.domainDetailContainer}>
              <img src={worldIcon} className={classes.domainIcon} />
              <p
                className={classes.domainTitle}
                style={{ color: "rgb(255,255,255)" }}
              >
                {sessionInformation.inf_url}
              </p>
            </div>
            <div className={classes.contactDetailContainer}>
              <img src={emailIcon} className={classes.contactImage} />
              <p
                className={classes.contactTitle}
                style={{ color: "rgb(255,255,255)" }}
              >{`Contact ${sessionInformation.inf_name}`}</p>
            </div>
          </div>
        </div>
        <div className={classes.contentContainer}>
          <div className={classes.contentDetailContainer}>
            <img
              src={PartyPopper}
              style={{ height: 60, width: 60, marginLeft: 43, marginTop: 40 }}
            />
            <p
              className={classes.contentTitle}
              style={{
                margin: 0,
                padding: 0,
                fontFamily: "IBMPlexSans-SemiBold",
                fontSize: 28,
                marginLeft: 43,
                marginTop: 11,
                color: "rgb(17, 16, 32)"
              }}
            >
              Payment Successful
            </p>
            <p
              style={{
                margin: 0,
                padding: 0,
                marginLeft: 43,
                marginRight: 108,
                marginTop: 3,
                fontSize: 20,
                color: "rgba(17,16,32)",
                fontFamily: "IBMPlexSans"
              }}
            >
              Your payment has been successfully completed.
            </p>
            <p
              style={{
                margin: 0,
                padding: 0,
                marginTop: 30,
                marginLeft: 63,
                color: "rgba(17,16,32,0.6)",
                fontFamily: "IBMPlexSans",
                fontSize: 17
              }}
            >
              Nickname
            </p>

            <div
              style={{
                borderRadius: 12,
                display: "flex",
                border: "1px solid rgba(31, 23, 105, 0.3)",
                marginLeft: 43,
                marginRight: 37,
                alignItems: "center"
              }}
            >
              <input
                ref={textAreaRef}
                id="outlined-basic-nickname"
                placeholder={"Not Required"}
                disabled={nickNameUpdated}
                style={{
                  flex: 1,
                  height: 55,
                  padding: 5,
                  color: "black",
                  fontFamily: "IBMPlexSans-Medium",
                  fontSize: 20,
                  border: "0px",
                  paddingLeft: 20,
                  paddingRight: 20,
                  background: "transparent",
                  "::placeholder": {
                    fontSize: 5,
                    lineHeight: 4
                  }
                }}
                className={classes.inputFocusClearOutline}
                defaultValue={nickName}
                onChange={e => {
                  e.preventDefault();
                  setNickName(e.target.value);
                }}
              />

              {nickNameUpdated === true ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  <img src={tickIcon} />
                  <p
                    style={{
                      margin: 0,
                      padding: 0,
                      color: "rgb(255, 170, 119)",
                      fontFamily: "IBMPlexSans-SemiBold",
                      fontSize: 20,
                      marginLeft: 7,
                      marginRight: 20
                    }}
                  >
                    Send
                  </p>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "rgba(255,170,119,1)",
                    width: 81,
                    borderRadius: 12,
                    height: 45,
                    marginRight: 5,
                    cursor: "pointer"
                  }}
                  onClick={event => {
                    event.preventDefault();

                    console.log("send nickname here!!");
                    setRandomNickname();
                  }}
                >
                  <p
                    style={{
                      margin: 0,
                      padding: 0,
                      color: "rgb(17, 16, 32)",
                      fontFamily: "IBMPlexSans-SemiBold",
                      fontSize: 20
                    }}
                  >
                    Send
                  </p>
                </div>
              )}
            </div>
            {/* </div> */}
            <p
              style={{
                fontFamily: "IBMPlexSans",
                margin: 0,
                padding: 0,
                marginLeft: 60,
                fontSize: 17,
                marginTop: 36,
                color: "rgba(17,16,32,0.6)"
              }}
            >
              Session Room Participation Link
            </p>
            <div
              style={{
                display: "flex",
                alignItem: "center",
                marginTop: 15,
                marginBottom: 15,
                marginLeft: 40,
                marginRight: 40,
                alignItems: "center",
                backgroundColor: "rgba(31,23,105,1)",
                borderRadius: 12
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  alignItems: "center",
                  color: "white"
                }}
              >
                <div
                  style={{
                    margin: 0,
                    padding: 5,
                    marginLeft: 15,
                    fontFamily: "IBMPlexSans-Medium",
                    fontSize: 20
                  }}
                >
                  {videoRoomUrl}
                </div>
              </div>
              <div
                style={{
                  right: 0,
                  top: 15,
                  color: "white",
                  backgroundColor: "rgba(31,23,105,1)",
                  border: "none",
                  fontSize: 20,
                  marginRight: 10,
                  alignItems: "center",
                  cursor: "pointer",
                  borderTopRightRadius: 12,
                  borderBottomRightRadius: 12,
                  fontFamily: "IBMPlexSans"
                }}
                onClick={e => {
                  e.preventDefault();
                  navigator.clipboard.writeText(videoRoomUrl);
                }}
              >
                Copy
              </div>
            </div>
            <div
              style={{
                display: "flex",
                height: 100,
                marginLeft: 60,
                marginRight: 60,
                marginTop: 14
              }}
            >
              <img src={WarningIcon} style={{ width: 30, height: 27 }} />
              <p
                style={{
                  margin: 0,
                  padding: 0,
                  color: "rgba(17,16,32,0.6)",
                  fontFamily: "IBMPlexSans",
                  marginLeft: 21,
                  marginRight: 20,
                  fontSize: 17,
                  letterSpacing: 0.4
                }}
              >
                Please take note of the session room participation link. You can
                join the session with that link address.
              </p>
            </div>
          </div>
          <div
            className={classes.joinSessionContainer}
            onClick={e => {
              e.preventDefault();

              console.log("*****");
              history.push(`/session-success/${params.attendeeId}`);

              return;
            }}
          >
            <p className={classes.joinSessionTitle}>{"Join the Session"} </p>
          </div>
          {/* <div className={classes.sessionDetailContainer}>
            <p className={classes.sessionDetailTitle}>
              Session price is $2.99 per participant
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default SetNickname;
